<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUser.id">
    <div class="text-center leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUser.firstname }}</p>
      <p class="font-semibold">{{ activeUser.lastname }}</p>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <vs-avatar
          v-if="activeUser && activeUser.id && activeUser.fileAvatar"
          :src="activeUser.avatar && activeUser.fileAvatar ? `${activeUser.fileAvatar}?bearer=${bearer}` : null"
          class="flex-shrink-0 mr-2 bg-primary"
          size="40px"
          :text="activeUser.firstname + ' ' + activeUser.lastname"
        />
        <vs-avatar
          v-else
          class="flex-shrink-0 mr-2 bg-primary"
          size="40px"
          :text="activeUser.firstname + ' ' + activeUser.lastname"
        />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <vs-dropdown-item
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="editProfile"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            {{ $t("navbar.dropdown.profile") }}
          </vs-dropdown-item>
          <vs-dropdown-item
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            {{ $t("navbar.dropdown.logout") }}
          </vs-dropdown-item>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import moduleUserManagementMixin from '@/store/user-management/moduleUserManagementMixin'

export default {
  mixins: [moduleUserManagementMixin],
  computed: {
    activeUser () {
      return this.$store.state.AppActiveUser
    },
    bearer () {
      return this.$store.getters['auth/accessToken']
    }
  },
  methods: {
    editProfile () {
      this.$router
        .push({
          name: 'page-profile',
          params: { userId: this.activeUser.id }
        })
        .catch(() => {})
    },
    logout () {
      this.$router.push({ name: 'page-logout' }).catch(() => {})
    }
  }
}
</script>

