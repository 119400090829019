/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: '/',
    name: 'Dashboard',
    i18n: 'navbar.menu.dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    role: 'user'
  },
  {
    name: 'Stock',
    i18n: 'navbar.menu.stock',
    slug: 'Stock',
    icon: 'FileIcon',
    role: 'stock',
    submenu: [
      {
        url: { name: 'stock-list-gadget', params: { type : 'gadget' } },
        name: 'Gadget & Brochure',
        i18n: 'navbar.menu.gadgetList',
        slug: 'gadget-list',
        icon: 'ListIcon',
        role: 'stock'
      },
      {
        name: 'Materiel',
        url: { name: 'stock-list-materiel', params: { type : 'materiel' } },
        i18n: 'navbar.menu.materiel',
        slug: 'Materiel',
        icon: 'FlagIcon',
        role: 'stockMateriel'
      }
    ]
  },
  {
    name: 'Pricing',
    i18n: 'navbar.menu.pricing',
    slug: 'Pricing',
    icon: 'FileIcon',
    role: 'pricing',
    submenu: [
      {
        url: { name: 'pricing-list' },
        name: 'Pricing',
        i18n: 'navbar.menu.pricingList',
        slug: 'Pricing-list',
        icon: 'ListIcon',
        role: 'pricing'
      },
      {
        name: 'Translation',
        url: { name: 'translations' },
        i18n: 'navbar.menu.translations',
        slug: 'Translation',
        icon: 'FlagIcon',
        role: 'sheet'
      }
    ]
  },
  {
    name: 'Gallery',
    i18n: 'navbar.menu.gallery',
    slug: 'Gallery',
    icon: 'ImageIcon',
    role: 'gallery',
    submenu: [
      {
        url: { name: 'gallery' },
        name: 'Gallery',
        i18n: 'gallery.navbar.submenu.list.media',
        slug: 'Gallery',
        icon: 'ListIcon',
        role: 'gallery'
      },
      {
        url: { name: 'tag' },
        name: 'Tag',
        i18n: 'gallery.navbar.submenu.list.tag',
        slug: 'Tag',
        icon: 'TagIcon',
        role: 'galleryAdmin'
      }
    ]
  },
  {
    name: 'Admin',
    i18n: 'navbar.menu.administration',
    slug: 'Administration',
    icon: 'SlidersIcon',
    role: 'admin',
    submenu: [
      {
        url: { name: 'activity-list' },
        name: 'Activities',
        i18n: 'navbar.menu.activities',
        slug: 'Administration-activities',
        icon: 'ActivityIcon',
        role: 'admin'
      },
      {
        url: { name: 'user-list' },
        name: 'Users',
        i18n: 'navbar.menu.users',
        slug: 'Administration-users',
        icon: 'UserIcon',
        role: 'admin'
      },
      {
        url: { name: 'user-group-list' },
        name: 'Groups',
        i18n: 'navbar.menu.groups',
        slug: 'Administration-groups',
        icon: 'UsersIcon',
        role: 'admin'
      }
    ]
  }
]
